@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@200&display=swap");

@font-face {
  font-family: TommyBold;
  src: url("../fonts/MADE\ Tommy\ Soft\ Bold\ PERSONAL\ USE.otf");
}

@font-face {
  font-family: TommyRegular;
  src: url("../fonts/MADE\ Tommy\ Soft\ Regular\ PERSONAL\ USE.otf");
}

@font-face {
  font-family: TommyLight;
  src: url("../fonts/MADE\ Tommy\ Soft\ Light\ PERSONAL\ USE.otf");
}

* {
  list-style: none;
  /* width: 100%; */
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: #fff;
  overflow-x: hidden;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
}

textarea,
button {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  outline: none;
  border: none;
}
